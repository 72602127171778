<template>
  <el-row class="warp">
    <el-col :span="24">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.mobile" placeholder="手机号" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.userName" placeholder="操作员姓名" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="searchForm.workNo" placeholder="员工编号" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-select v-model="searchForm.roleId" placeholder="请选择角色" filterable reserve-keyword @change="handleSearch">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button type="primary" @click="handleReset">重置</el-button>
          <el-button type="primary" @click="showAddEditDialog()">新增</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60" />
        <el-table-column prop="userInfo.mobile" label="手机号" width="140" />
        <el-table-column prop="userInfo.userName" label="操作员姓名" width="100" />
        <el-table-column prop="userInfo.workNo" label="操作员员工编号" width="160" />
        <el-table-column prop="userInfo.mainOrgChain" label="操作员所在组织" width="340" />
        <el-table-column prop="roleNames" label="角色" width="140" />
        <el-table-column prop="createTime" label="创建时间" min-width="160" />
        <el-table-column prop="state" label="状态" min-width="160" :formatter="userStatusFormatter" />
        <el-table-column label="操作" fixed="right" width="220">
          <template slot-scope="scope">
            <el-button size="small" @click="showAddEditDialog(scope.row.userId)">编辑</el-button>
            <el-button @click.stop="handleStatus(scope.row)" size="small" :type="scope.row.state == 1 ? 'warning' : 'primary'">
              {{ scope.row.state == 1 ? '禁用' : '启用' }}
            </el-button>
            <!-- <el-button type="danger" @click="delUser(scope.$index, scope.row)" size="small">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="10"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
      <!--新增编辑-->
      <el-dialog :title="addEditTitle" :visible.sync="addEditFormVisible" :close-on-click-modal="false" width="500px">
        <el-form :model="addEditForm" label-width="100px" :rules="addEditFormRules" ref="addEditForm" size="small">
          <el-form-item label="操作员姓名" prop="dataUserId">
            <el-select
              v-if="addEditType === 'add'"
              v-model="addEditForm.dataUserId"
              filterable
              remote
              placeholder="输入操作员姓名进行查找"
              :filter-method="remoteMethod"
              :loading="nameLoading"
              style="width:100%"
              @change="changeUser"
            >
              <el-option v-for="item in userList" :key="item.userId" :label="item.userName" :value="item.userId" class="username-list">
                <div class="name">{{ item.userName }}</div>
                <p class="org">{{ item.mainJob.orgChain }}</p>
              </el-option>
            </el-select>
            <el-input v-else v-model="addEditForm.userName" disabled></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleIdList">
            <el-select v-model.trim="addEditForm.roleIdList" multiple pplaceholder="请选择角色" style="width:100%">
              <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作员账号" prop="">
            {{ adminAccount }}
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addEditFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click.native="addEditSubmit" size="small">确定 </el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
import API from '@/api/system/api_user'
import PagesMixins from '@/mixins/pagesMixins.js'
import _ from 'lodash'
const searchForm = {
  mobile: null,
  userName: null,
  roleId: null
  // workNo: null
}
const addEditForm = {
  dataUserId: null,
  roleIdList: [],
  userName: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],
      roleList: [],

      roles: [],
      addEditForm: _.cloneDeep(addEditForm),
      //编辑相关数据
      addEditTitle: '编辑',
      addEditType: 'edit',
      addEditFormVisible: false, //编辑界面是否显示
      addEditFormRules: {
        dataUserId: [{ required: true, message: '请输入操作员姓名', trigger: 'change' }],
        roleIdList: [{ required: true, message: '请选择角色', trigger: 'change' }]
      },
      userList: [],
      nameLoading: false,
      adminAccount: ''
    }
  },
  mounted() {
    this.handleSearch()
    this.querySearchRoleName()
  },
  methods: {
    userStatusFormatter(row) {
      return row.state == 1 ? '启用' : '禁用'
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    handleSearch() {
      this.page = 1
      this.getListData()
    },
    //获取用户列表
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      API.pagelist(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    querySearchRoleName() {
      API.findRoleList().then(result => {
        if (result && result.code === '000000') {
          this.roleList = result.data.list
        }
      })
    },
    remoteMethod(name) {
      if (name) {
        let params = {
          name,
          isAccess: 0
        }
        this.nameLoading = true
        API.getUserInfo(params).then(result => {
          this.nameLoading = false
          if (result && result.code === '000000') {
            this.userList = result.data
          }
        })
      }
    },
    //显示新增编辑界面
    changeUser(val) {
      console.log(val)
      this.adminAccount = this.userList.find(e => e.userId === val).mobile
    },
    getDetail(id) {
      let params = {
        dataUserId: id
      }
      return API.detail(params).then(result => {
        if (result && result.code === '000000') {
          return (this.userList = result.data)
        }
      })
    },
    async showAddEditDialog(id) {
      this.userList = []
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      if (id) {
        this.addEditTitle = '编辑'
        this.addEditType = 'edit'
        let addedit = await this.getDetail(id)
        for (let key in this.addEditForm) {
          this.addEditForm[key] = addedit[key]
        }
        this.adminAccount = addedit.mobile
      } else {
        this.addEditTitle = '新增'
        this.addEditType = 'add'
        this.adminAccount = null
        this.addEditForm = _.cloneDeep(addEditForm)
      }
      this.addEditFormVisible = true
    },
    //编辑
    addEditSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          let response
          if (this.addEditType == 'add') {
            response = API.add(params)
          } else {
            response = API.edit(params)
          }
          response.then(result => {
            if (result && result.code === '000000') {
              this.$message.success({
                message: this.addEditType == 'add' ? '新增成功' : '修改成功',
                duration: 2000
              })
              this.addEditFormVisible = false
              this.getListData()
            }
          })
        }
      })
    },
    //删除
    delUser(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' }).then(() => {
        API.delete({ dataUserId: row.userId }).then(result => {
          if (result && result.code === '000000') {
            this.$message.success({ showClose: true, message: '删除成功', duration: 1500 })
            this.getListData()
          }
        })
      })
    },
    /* 确认禁用 */
    handleStatus(row) {
      let statusName = row.state == 1 ? '确定要禁用此用户吗？' : '确定要启用此用户吗？'
      this.$confirm(statusName, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.enableData(row)
        })
        .catch(err => {
          console.log(err)
        })
    },
    /* 禁用 */
    async enableData(row) {
      let params = {
        dataUserId: row.userId,
        state: row.state == 1 ? 0 : 1
      }
      let result = await API.enable(params)
      if (result && result.code === '000000') {
        this.getListData()
        this.$message.success({
          showClose: true,
          message: '操作成功',
          duration: 2000
        })
      } else {
        this.$message.error({
          showClose: true,
          message: result.msg || '请求出错',
          duration: 2000
        })
      }
    }
  }
}
</script>
<style lang="scss">
.el-select-dropdown {
  .username-list {
    white-space: pre-wrap;
    padding: 5px 20px;
    height: auto;
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 24px;
    }
    .org {
      font-size: 12px;
      color: #b4b4b4;
      line-height: 20px;
      max-width: 320px;
    }
    .highlighted .org {
      color: #ddd;
    }
  }
}
</style>
