import * as http from '../http'

export default {
  //列表
  pagelist: params => {
    return http.post('/administrator/list', params)
  },
  add: params => {
    return http.post('/administrator/add', params)
  },
  edit: params => {
    return http.post('/administrator/update', params)
  },
  detail: params => {
    return http.post('/administrator/edit', params)
  },
  delete: params => {
    return http.post('/administrator/delete', params)
  },
  enable: params => {
    return http.post('/administrator/enable', params)
  },
  //查询角色下拉框
  findRoleList: params => {
    return http.post('/role/combobox', params)
  },
  //根据用户名称查询
  getUserInfo: params => {
    return http.post('/user/query-by-name', params, { loading: false })
  }
}
